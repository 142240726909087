import "./styles.scss"

import React, { useState, useRef, useEffect } from "react"

import DropdownArrow from "assets/icons/down-arrow-black.svg"

const getTitle = option => {
  switch (option) {
    case "Google":
      return "Google"
    case "Facebook":
      return "Facebook"
    default:
      return "Wszystkie opinie"
  }
}

const SourcesSelect = ({ currentSource, setCurrentSource }) => {
  const [accordionOpen, setAccordionOpen] = useState(false)
  const sources_list = useRef()

  const title = getTitle(currentSource)

  useEffect(() => {
    const clickedOutside = e => {
      if (
        accordionOpen &&
        sources_list.current &&
        !sources_list.current.contains(e.target)
      ) {
        setAccordionOpen(false)
      }
    }
    document.addEventListener("click", clickedOutside)

    return () => {
      document.removeEventListener("click", clickedOutside)
    }
  }, [accordionOpen])

  return (
    <div
      ref={sources_list}
      className={`sources-select${accordionOpen ? " open" : ""}`}
    >
      <button
        type="button"
        className="sources-select__box"
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <span>{title}</span>
        <img
          className="sources-select__icon"
          style={accordionOpen ? { transform: "rotate(180deg)" } : null}
          src={DropdownArrow}
          alt=""
        />
      </button>
      <ul className="sources-select__list">
        <li
          className={`sources-select__list-item${
            currentSource === "Google" ? " hide" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => {
              setCurrentSource("Google")
              setAccordionOpen(!accordionOpen)
            }}
          >
            Google
          </button>
        </li>
        <li
          className={`sources-select__list-item${
            currentSource === "Facebook" ? " hide" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => {
              setCurrentSource("Facebook")
              setAccordionOpen(!accordionOpen)
            }}
          >
            Facebook
          </button>
        </li>
        <li
          className={`sources-select__list-item${
            currentSource === null ? " hide" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => {
              setCurrentSource(null)
              setAccordionOpen(!accordionOpen)
            }}
          >
            Wszystkie opinie
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SourcesSelect
