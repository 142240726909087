import "./styles.scss"

import React from "react"

import Button from "components/Button"
import TestimonialTail from "components/TestimonialTail"

const TestimonialTails = ({
  testimonials_to_show,
  showAll,
  setShowAll,
  show_more,
}) => {
  return (
    <section className="testimonial-tails">
      <div className="container">
        <div className="row">
          {testimonials_to_show?.map(({ id, slug, acfTestimonials }) => {
            return (
              <div key={id} className="col-md-6 col-12">
                <TestimonialTail testimonial={acfTestimonials} slug={slug} />
              </div>
            )
          })}
        </div>
        {show_more > 10 && (
          <Button
            type="button"
            className={`button-dark-green${showAll ? " hide" : ""}`}
            onClick={() => setShowAll(true)}
          >
            Pokaż więcej opinii
          </Button>
        )}
      </div>
    </section>
  )
}

export default TestimonialTails
