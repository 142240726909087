import "./styles.scss"

import React from "react"

import SourcesSelect from "../SourcesSelect"

const Sources = ({ currentSource, setCurrentSource }) => {
  return (
    <section className="testimonial-source">
      <div className="container">
        <div className="testimonial-source__list--wrapper">
          <p className="testimonial-source__list-desc">Pokaż opinie</p>
          <SourcesSelect
            currentSource={currentSource}
            setCurrentSource={setCurrentSource}
          />
        </div>
      </div>
    </section>
  )
}

export default Sources
