import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { Sources, TestimonialTails } from "page_components/testimonials"

let filtered_testimonials = []
let testimonials_to_show = []

const Testimonials = ({ data }) => {
  const [currentSource, setCurrentSource] = useState(null)
  const [showAll, setShowAll] = useState(false)

  const breadcrumbs_data = [
    {
      name: "Opinie",
      href: "/opinie/",
    },
  ]

  const testimonials_list = data?.allWpTestimonial?.nodes

  if (currentSource === null) {
    filtered_testimonials = testimonials_list
  } else {
    filtered_testimonials = testimonials_list?.filter(
      testimonial => testimonial.acfTestimonials.source === currentSource
    )
  }

  if (showAll) {
    testimonials_to_show = filtered_testimonials
  } else {
    if (filtered_testimonials.length > 10) {
      testimonials_to_show = filtered_testimonials.slice(0, 10)
    } else {
      testimonials_to_show = filtered_testimonials
    }
  }

  const show_booksy_option = testimonials_list?.some(
    testimonial => testimonial?.acfTestimonials?.source === "Booksy"
  )

  return (
    <Layout
      seo={{
        title: "Opinie",
        description:
          "Wykonujemy usługi najwyższej jakości, dlatego pacjenci polecają nas dalej aż 80% pacjentów z polecenia",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Opinie" />
      <Sources
        currentSource={currentSource}
        setCurrentSource={setCurrentSource}
        show_booksy_option={show_booksy_option}
      />
      <TestimonialTails
        testimonials_to_show={testimonials_to_show}
        showAll={showAll}
        setShowAll={setShowAll}
        show_more={filtered_testimonials?.length}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpTestimonial(sort: { fields: date, order: DESC }) {
      nodes {
        acfTestimonials {
          date(locale: "pl")
          name
          review
          score
          source
          screenshot {
            altText
            sourceUrl
          }
        }
        id
        slug
      }
    }
  }
`

export default Testimonials
